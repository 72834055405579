(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("mobx"), require("sbJsExtends"), require("sbDataLayer"));
	else if(typeof define === 'function' && define.amd)
		define("sbSportsContentLib", ["mobx", "sbJsExtends", "sbDataLayer"], factory);
	else if(typeof exports === 'object')
		exports["sbSportsContentLib"] = factory(require("mobx"), require("sbJsExtends"), require("sbDataLayer"));
	else
		root["sbSportsContentLib"] = factory(root["mobx"], root["sbJsExtends"], root["sbDataLayer"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__14__) {
return 